<template>
  <div>
    <CSpinner v-if="$apollo.queries.salesAgent.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="sales agent"
        :name="itemName"
        :nid="salesAgent.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CNavbar
        type="light"
        class="navbar-partner"
    >
      <CNavItem
          :to="this.salesOfficeNid? `/partners/sales-office/${this.salesOfficeNid}`: '/partners/vendors'"
          class="nav-item-partner"
      >
        Back to Sales Office
      </CNavItem>
    </CNavbar>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: salesOfficeNid? `/partners/sales-office/${salesOfficeNid}`: '/partners/vendors'})"
            />
          </CCol>
          <CCol sm="8">
            <h2>{{itemName}}</h2>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SalesAgentForm
            v-bind:sales-agent-in="salesAgentEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <DetailsButtonGroup
            v-on:save="saveItem"
            v-on:cancel="resetItem"
            v-on:delete-prompt="toggleDeleteItemModal"
            v-on:close="$router.push({path: salesOfficeNid? `/partners/sales-office/${salesOfficeNid}`: '/partners/vendors'})"
        />
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import SalesAgentForm from "@/apps/partners/components/SalesAgentForm";
const clonedeep = require('lodash.clonedeep')

const querySalesAgent = gql`
query salesAgent($nid: ID!) {
  salesAgent(nid: $nid) {
    nid
    activePapp
    msActivePapp
    imActivePapp
    externalId
    salesOffice{
      nid
      name
    }
    firstName
    lastName
    gender
    email
    phoneCountryPrefix
    phone
    addressLine1
    postalCode{
      nid
      postalCode
    }
    city
    country{
      nid
      name
    }
    position
    mandatoryMember
    avatar{
      hrefOrig
    }
    handoverRegions{
      numericPostalCode
    }
    displayRegions{
      numericPostalCode
    }
    msHandoverRegions{
      numericPostalCode
    }
    msDisplayRegions{
      numericPostalCode
    }
    imHandoverRegions{
      numericPostalCode
    }
    imDisplayRegions{
      numericPostalCode
    }
   }
}
`
const updateSalesAgent = gql`
  mutation updateSalesAgent($nid: ID!, $salesAgent: SalesAgentInput!){
    updateSalesAgent(nid: $nid, salesAgent: $salesAgent){
        nid
    }
  }
`
const deleteSalesAgent = gql`
  mutation deleteSalesAgent($nid: ID!){
    deleteSalesAgent(nid: $nid)
  }
`

export default {
  name: "SalesAgent",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    SalesAgentForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      salesAgent: {},
      salesAgentEdited: {},
      salesAgentSave: {}
    }
  },
  methods: {
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    updateItem(data){
      this.salesAgentSave = data
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.salesAgentSave;
      this.$apollo.mutate({
        mutation: updateSalesAgent,
        variables: {
          nid: nid,
          salesAgent: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.salesAgentEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: `/partners/sales-office/${this.salesOfficeNid}`});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.salesAgentEdited = clonedeep(this.$apolloData.data.salesAgent);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteSalesAgent,
        variables: {
          nid: this.salesAgent.nid
        }
      })
      .then(res => {
        this.$emit('delete-sales-agent', this.salesAgent.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: `/partners/sales-office/${this.salesOfficeNid}`});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    }
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return `${this.salesAgent.firstName} ${this.salesAgent.lastName}`
    },
    salesOfficeNid(){
      return this.salesAgent.salesOffice? this.salesAgent.salesOffice.nid: null;
    }
  },
  apollo: {
    salesAgent: {
      query: querySalesAgent,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.sales_agent_nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>

<style scoped>
  .navbar-partner{
    align-items: start;
    align-content: start;
    justify-content: start;
    padding-left: 0px;
    margin-left: 0px;
  }
  .nav-item-partner{
    list-style: none;
  }
</style>
